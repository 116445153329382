<!-- 添加编辑计价弹窗 -->
<template>
  <el-dialog
      width="60%"
      center
      :visible="visible"
      :lock-scroll="false"
      :destroy-on-close="true"
      custom-class="ele-dialog-form"
      :title="isUpdate?'添加计价':'编辑计价'"
      @update:visible="updateVisible">
    <el-form
        ref="form"
        :model="form"
        label-width="80px">
      <el-row :gutter="18">
        <el-col :span="6">
          <el-form-item label="公式名称:" prop="">
            <el-input
                clearable
                :maxlength="20"
                v-model="form.nickname"
                placeholder="请输入公式名称"/>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="优先级:" prop="">
            <el-select
                clearable
                v-model="form.status"
                placeholder="请选择"
                class="ele-fluid">
              <el-option label="1级" value="1"/>
              <el-option label="2级" value="2"/>
              <el-option label="3级" value="3"/>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="计价类型:" prop="">
            <el-select
                clearable
                v-model="form.status"
                placeholder="请选择"
                class="ele-fluid">
              <el-option label="一类车(客车≤7座,货车≤2吨)" value="1"/>
              <el-option label="2级" value="2"/>
              <el-option label="3级" value="3"/>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="启用:" prop="">
            <img v-if="status == 0" style="width: 76px;height: 34px; cursor: pointer;" src="@/assets/images/home/yiqiyong.png" alt="" @click="changeStatus()">
            <img v-else style="width: 76px;height: 34px; cursor: pointer;" src="@/assets/images/home/weiqiyong.png" alt="" @click="offState()">
          </el-form-item>
        </el-col>
      </el-row>

      <!-- 主体内容 -->
      <el-row :gutter="15">
        <!--左-->
        <el-col :span="18">
          <div class="pricingRules">
            <!-- 拖车 -->
            <div class="pricing" v-for="(item,index) in 4" :key="index">
              <div class="pricingRules_t_bg">
                {{item}}{{index}}
                <span style="vertical-align: middle;">0001拖车</span>
                <el-radio-group v-model="trailerradio" style="margin-left: 10px;">
                  <el-radio :label="1">里程计费</el-radio>
                  <el-radio :label="2">一口价</el-radio>
                </el-radio-group>
              </div>
              <!--去程-->
              <div class="trip">
                <el-checkbox v-model="tripchecked">去程</el-checkbox>
              </div>
              <!--背程-->
              <el-row>
                <el-col :span="12">
                  <el-form-item label="起步价" prop="">
                    <el-input placeholder="请输入" v-model="form.qibujia">
                      <template slot="append">
                        <div>元</div>
                      </template>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="起步公里" prop="">
                    <el-input placeholder="请输入" v-model="form.qibujia">
                      <template slot="append">
                        <div>公里</div>
                      </template>
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>

            </div>

          </div>
        </el-col>

        <!--右-->
        <el-col :span="6">
          <div class="provinceAndCity">
            <div class="provinceAndCity_t">
              <span style="vertical-align: middle">区域：</span>
              <el-checkbox v-model="selectAll">全选</el-checkbox>
            </div>
            <!--省市区-->
            <div class="provinceAndCity_b">
              <!--<el-tree-->
              <!--    :data="list"-->
              <!--    show-checkbox-->
              <!--    node-key="id"-->
              <!--    :props="defaultProps">-->
              <!--</el-tree>-->
            </div>

          </div>
        </el-col>

      </el-row>

    </el-form>


    <div slot="footer">
      <el-button
          @click="updateVisible(false)">取消
      </el-button>
      <el-button
          style="margin-left: 20px;"
          type="primary"
          :loading="loading"
          @click="save">提交
      </el-button>
    </div>
  </el-dialog>
</template>

<script>

export default {
  name: 'UserEdit',
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: Object.assign({status: 1}, this.data),
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,
      // 启用状态
      status:0,

      // 拖车选项
      trailerradio:1,
      // 拖车选项 去程
      tripchecked:true,

      // 区域 全选按钮
      selectAll:true,

      // 省市区
      list: [{
        id: 1,
        label: '河北省',
        children: [{
          id: 4,
          label: '邯郸市',
          children: [{
            id: 9,
            label: '肥乡区'
          }, {
            id: 10,
            label: '魏县'
          }]
        }]
      }, {
        id: 2,
        label: '北京市',
        children: [{
          id: 5,
          label: '丰台区'
        }, {
          id: 6,
          label: '大兴区'
        }]
      }],
      defaultProps: {
        children: 'children',
        label: 'label'
      }

    };
  },
  watch: {
    data() {
      if (this.data) {
        console.log(this.data)
        this.form = Object.assign({}, this.data);
        this.isUpdate = true;
      } else {
        this.form = {};
        this.isUpdate = false;
      }
    }
  },
  mounted() {

  },
  methods: {
    // 关闭状态
    changeStatus(){
      this.status = 1;
    },
    // 开启状态
    offState(){
      this.status = 0;
    },

    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.updateVisible(false);
          this.$emit('done');
          this.loading = false;
        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },
  }
}
</script>

<style scoped lang="scss">
.pricing{
  margin: 15px;
}
.pricingRules{
  background: #ffffff;
  border: 1px solid #e3e4e9;
  padding: 10px;
}
.provinceAndCity{
  background: #ffffff;
  border: 1px solid #e3e4e9;
  padding: 10px;
}


.pricingRules_t_bg{
  padding: 10px 15px 10px 15px;
  background: #f0f1f3;
  border: 1px solid #dfe2e7;
  font-size: 16px;
  font-weight: 500;
  color: #242832;
}

.trip{
  padding: 15px;
}

.provinceAndCity_b{
  margin-top: 10px;
}

</style>
